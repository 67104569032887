<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1761 568" fill="none">
        <g fill="currentColor" clip-path="url(#a)">
            <path
                d="M700.613 94.667c-48.475 0-96.95 18.554-133.874 55.474-74.038 74.03-74.038 193.878 0 267.718 36.924 36.92 85.399 55.474 133.874 55.474 48.475 0 96.95-18.554 133.874-55.474 74.038-74.03 74.038-193.878 0-267.718-36.924-36.92-85.399-55.474-133.874-55.474Zm67.032 256.357c-17.8 17.797-41.659 27.643-67.032 27.643-25.374 0-49.043-9.846-67.032-27.643-17.988-17.797-27.646-41.653-27.646-67.024 0-25.371 9.847-49.037 27.646-67.024 17.8-17.797 41.658-27.643 67.032-27.643 25.373 0 49.043 9.846 67.032 27.643 17.799 17.797 27.645 41.653 27.645 67.024 0 25.371-9.846 49.037-27.645 67.024Z"
            />
            <path
                d="M302.968 94.667c-48.475 0-96.95 18.554-133.874 55.474-74.038 74.03-74.038 193.878 0 267.718 36.924 36.92 85.399 55.474 133.874 55.474 48.475 0 96.949-18.554 133.874-55.474 74.037-74.03 74.037-193.878 0-267.718-36.925-36.92-85.399-55.474-133.874-55.474Zm67.031 256.357c-17.799 17.797-41.658 27.643-67.031 27.643-25.374 0-49.043-9.846-67.032-27.643-17.989-17.797-27.646-41.653-27.646-67.024 0-25.371 9.847-49.037 27.646-67.024 17.799-17.797 41.658-27.643 67.032-27.643 25.373 0 49.043 9.846 67.031 27.643 17.8 17.797 27.646 41.653 27.646 67.024 0 25.371-9.846 49.037-27.646 67.024ZM1098.26 94.667c-37.3 0-74.61 11.17-106.798 33.133l-82.559-33.133V568h94.677V447.773c29.16 16.851 61.92 25.56 94.68 25.56 48.47 0 96.95-18.554 133.87-55.474 74.04-74.03 74.04-193.878 0-267.718-36.92-36.92-85.4-55.474-133.87-55.474Zm67.03 256.357c-17.8 17.797-41.66 27.643-67.03 27.643-25.38 0-49.04-9.846-67.03-27.643-17.99-17.797-27.65-41.653-27.65-67.024 0-25.371 9.85-49.037 27.65-67.024 17.8-17.797 41.65-27.643 67.03-27.643 25.37 0 49.04 9.846 67.03 27.643 17.8 17.797 27.65 41.653 27.65 67.024 0 25.371-9.85 49.037-27.65 67.024ZM1495.9 208.267c0 41.842-33.89 75.733-75.74 75.733 41.85 0 75.74 33.891 75.74 75.733 0-41.842 33.9-75.733 75.75-75.733-41.85 0-75.75-33.891-75.75-75.733ZM1685.26 151.467c0-31.24 25.37-56.8 56.8-56.8-31.43 0-56.8-25.371-56.8-56.8 0 31.429-25.38 56.8-56.81 56.8 31.43 0 56.81 25.37 56.81 56.8Z"
            />
            <path
                d="M1676.55 340.421c12.5-39.57 12.12-83.496-4.17-125.338-29.16-74.787-100.73-120.606-176.48-120.606-22.91 0-46.01 4.166-68.73 12.875-97.52 37.867-145.62 147.68-107.75 245.187 29.17 74.786 100.74 120.605 176.48 120.605 22.92 0 46.02-4.165 68.74-12.875 23.86-9.277 44.5-22.909 61.92-39.381l84.26 52.445 49.99-80.466-84.45-52.446h.19Zm-93.92-18.554c-10.23 23.098-28.78 40.896-52.26 50.173a94.062 94.062 0 0 1-34.28 6.437c-39.38 0-74.04-23.666-88.24-60.397-9.09-23.477-8.71-49.227 1.52-72.515 10.22-23.098 28.78-40.896 52.26-50.173a93.971 93.971 0 0 1 34.27-6.437c39.39 0 74.04 23.666 88.24 60.397 9.09 23.477 8.71 49.227-1.51 72.515ZM0 473.333h94.677V37.867L0 0v473.333Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h1761v568H0z" />
            </clipPath>
        </defs>
    </svg>
</template>
